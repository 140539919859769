import React from 'react'
import AdSense from 'react-adsense'
import styled from '@emotion/styled'

import amznPrime from '../images/primeVideo.jpg'
import { Container, UncontrolledAlert } from 'reactstrap'

export default class AdComponent extends React.Component {
render () {
    const classNames = !this.props.customClasses ? 'my-2' : this.props.customClasses
    // Force horizontal formats
    const style = !this.props.horizontal 
    ? { display: 'block' } 
    : { display: 'inline-block', minWidth: 320, maxWidth:970, width:'100%', height:90 } 
    const format = this.props.format ? this.props.format : "auto"
    return (
      <div className="w-100">
          <AdSense.Google
            className={classNames}
            client={this.props.client}
            slot={this.props.slot}
            style={style}
            format={format}
            responsive='true'
          />
      </div>
    );
  }
}

export const AdsenseHalfPage = ({client, slot}) => (
  <div>
    <AdSense.Google
      className="my-2"
      client={client}
      slot={slot}
      style={{ display: 'inline-block',width:300,height:600}}
      format='auto'
      responsive='true'
    />
  </div>
)

export const FullWidthAd = ({client, slot}) => (
  <StoryContainer>
      <AdSense.Google
        className="my-2"
        client={client}
        slot={slot}
        style={{ display: 'block'}}
        format='auto'
        responsive='true'
      />
  </StoryContainer>
)

export const FullWidthAdBlue = ({client, slot}) => (
  <StoryContainerBlue>
    <Container>
      <AdSense.Google
        className="my-2"
        client={client}
        slot={slot}
        style={{ display: 'block', margin:"auto" }}
        format='auto'
        responsive='true'
      />
    </Container>
  </StoryContainerBlue>
)
export const DismissibleFullWidthAdBlue = ({client, slot}) => (
  <UncontrolledAlert color="info" style={{backgroundColor: '#346bc2', color: '#fff'}} className="mb-0">
    <DismissibleStoryContainerBlue>
      <Container>
        <AdSense.Google
          className="my-2"
          client={client}
          slot={slot}
          style={{ display: 'block', margin:"auto" }}
          format='auto'
          responsive='true'
        />
      </Container>
    </DismissibleStoryContainerBlue>
  </UncontrolledAlert>
)

export const NativeAd = ({client, slot}) => (
  <div className="w-100">
    <AdSense.Google
      client={client}
      slot={slot}
      style={{ display: 'block' }}
      layout='in-article'
      format='fluid'
    />
  </div>
)

export const AmazonAd = ({client, slot}) => (
  <StoryContainer>
    <a href="https://www.primevideo.com/?tag=ctimes-21" target="_blank">
      <img src={amznPrime}></img>
    </a>
  </StoryContainer>
)


const StoryContainer = styled.div`
  // background-color: rgb(247, 247, 247);
  // border: 1px solid rgb(243, 243, 243);
  display: block;
  padding-bottom: 15px;
  padding-top: 15px;
  margin: 0px;
  min-height: 90px;
  text-align: center;
  overflow: scroll;
`

const StoryContainerBlue = styled.div`
  background-color: #346bc2;
  border: 1px solid rgb(243, 243, 243);
  display: block;
  padding-bottom: 15px;
  padding-top: 15px;
  margin: 0px;
  min-height: 90px;
  text-align: center;
  overflow: scroll;
`
const DismissibleStoryContainerBlue = styled.div`
  background-color: #346bc2;
  color: #fff;
  display: block;
  // padding-bottom: 15px;
  // padding-top: 15px;
  margin: 0px;
  min-height: 90px;
  text-align: center;
  overflow: scroll;
`
